import React, { FC, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap-v5";
import { useIntl } from "react-intl";
import CloseButton from "../../../sharedComponents/closeButton/CloseButton";

interface Props {
  show: boolean;
  previewFileURL: string | undefined;
  previewFileExtension: string | undefined;
  closeModal?: () => void;
}

const ProductFilePreviewModal: FC<Props> = ({
  show,
  previewFileURL,
  closeModal,
  previewFileExtension,
}) => {
  const intl = useIntl();
  const [previewURL, setPreviewURL] = useState<string | undefined>(
    previewFileURL
  );
  const [previewExtension, setPreviewExtension] = useState<string | undefined>(
    previewFileExtension
  );
  useEffect(() => {
    setPreviewURL(previewFileURL);
    setPreviewExtension(previewFileExtension);
  }, [previewFileURL]);
  return (
    <div>
      <Modal
        show={show}
        centered
        dialogClassName="medium-size-modal"
        // contentClassName={`${loading ? "pe-none" : ""}`}
        backdrop="static"
      >
        <Modal.Header>
          <div className="d-flex flex-row flex-fill align-items-center justify-content-between">
            <Modal.Title className="p-0 m-0">
              {intl.formatMessage({
                id: "PRODUCT_FILE_PREVIEW_TITLE",
              })}
            </Modal.Title>
            <CloseButton
              onClose={() => {
                // setShowEditTaskModal(true);
                closeModal?.();
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="">
          {previewURL && (
            <>
              {previewExtension === "png" ||
              previewExtension === "jpg" ||
              previewExtension === "jpeg" ||
              previewExtension === "svg" ? (
                <div className="d-flex justify-content-center align-items-center">
                  {" "}
                  <img
                    src={previewFileURL}
                    style={{
                      objectFit: "contain",
                      width: "500px",
                      height: "auto !important",
                    }}
                  />
                </div>
              ) : (
                <iframe
                  src={`${previewURL}`}
                  width={"100%"}
                  height={"500"}
                ></iframe>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProductFilePreviewModal;
