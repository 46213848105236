import React, { FC, useEffect, useState } from "react";
import { DealType, LogType, PhaseLogType } from "../types/DealResponseType";
import { getPhaseLogs } from "../redux/DealsAPI";
import moment from "moment";
import { KTSVG } from "../../../../_metronic/helpers";
import Constants from "../../../config/Constants";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";

interface Props {
  deal: DealType;
}

const DealLogs: FC<Props> = ({ deal }) => {
  // state
  const [phaseLogs, setPhaseLogs] = useState<PhaseLogType[]>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const [loadig, setLoading] = useState(false);

  const lang = useLang();

  const onPageClick = (page: number) => {
    setActivePage(page);
  };
  useEffect(() => {
    setLoading(true);
    getPhaseLogs(deal.id)
      .then(({ data: { logs } }) => {
        setPhaseLogs(logs);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedLogs =
    phaseLogs && phaseLogs.length > 10
      ? phaseLogs.slice(startIndex, startIndex + itemsPerPage)
      : phaseLogs;

  useEffect(() => {
    if (paginatedLogs.length === 0 && phaseLogs.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedLogs]);

  return (
    <div
      className=""
      style={{
        flex: 1,
      }}
    >
      <div className="card-header border-0 pt-2 ">
        <div className="card-title m-0 d-flex ">
          <div className=" ">
            <span>{deal.name}</span>
            <div>
              <span className="text-muted fs-8">{`Below are the logs for ${deal.phase_name} pipeline`}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body ">
        {loadig && (
          <tr className="odd d-flex align-items-center justify-content-center">
            <td valign="top" colSpan={7} className="dataTables_empty">
              <div className="d-flex flex-column flex-center">
                <SimpleLoader />
              </div>
            </td>
          </tr>
        )}
        <div className="timeline">
          {paginatedLogs.length > 0 ? (
            <>
              {paginatedLogs?.map((log, index) => {
                return (
                  <>
                    <div className="timeline-item" key={index}>
                      <div className="timeline-line w-40px"></div>

                      <div className="timeline-icon symbol symbol-circle symbol-40px">
                        <div className="symbol-label bg-light">
                          <KTSVG
                            path="/media/icons/duotune/communication/com009.svg"
                            className="svg-icon-2 svg-icon-gray-500"
                          />
                        </div>
                      </div>

                      <div className="timeline-content mb-10 mt-n2">
                        <div className="overflow-auto pe-3">
                          <div className="fs-5 fw-bold mb-2">
                            {` Deal moved from  ${log.pipelines_phases_from_name} to ${log.pipelines_phases_to_name}`}
                          </div>

                          <div className="d-flex align-items-center mt-1 fs-6">
                            <div className="text-muted me-2 fs-7">
                              {`moved at ${moment(log.created_at).format(
                                lang === "en" ? "DD-MM-YYYY" : "DD.MM.YYYY"
                              )} by`}
                            </div>

                            <div
                              className="symbol symbol-circle symbol-25px text-primary"
                              data-bs-toggle="tooltip"
                              data-bs-boundary="window"
                              data-bs-placement="top"
                            >
                              {/* <img
                                src={log.}
                                alt="img"
                              /> */}
                              <span>{log.recorded_by_name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="d-flex  mb-4" key={index}>
                <SVGICON
                  src={LogIcon}
                  className="svg-icon-2 me-4 svg-icon-primary"
                />

              </div> */}
                  </>
                );
              })}
            </>
          ) : (
            <div className="text-muted fs-8 d-flex align-items-center justify-content-center">
              Logs not created
            </div>
          )}
        </div>
      </div>

      {/* end::Table */}
      {paginatedLogs.length > 0 && (
        <Pagination
          totalPages={Math.ceil(paginatedLogs.length / itemsPerPage)}
          activePage={
            Math.ceil(paginatedLogs.length / itemsPerPage) === 1
              ? 1
              : activePage
          }
          onPageClick={onPageClick}
          noCard
          itemsPerPage={itemsPerPage}
          showItemsPerPage
          setItemsPerPage={(count) => {
            setItemsPerPage(count);
          }}
        />
      )}
    </div>
  );
};

export default DealLogs;
