import React, { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import Config from "../../config";
import DealsList from "./components/DealsList";
import EditDeal from "./components/EditDeal";
import { useSelector } from "react-redux";
import { RootState } from "../../../setup";

interface Props {
  pipelineInformation: number;
}

const DealsPage: FC<Props> = ({ pipelineInformation }) => {
  const id = useSelector<RootState>(({ auth }) => auth.pipelineId) as number;
  const intl = useIntl();

  const dealsBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: "MENU_DASHBOARD" }),
      path: "/dashboard",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: "MENU_DEALS" }),
      path: `/content/deals`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];
  return (
    <div>
      <Helmet>
        <title>
          {Config.APP_NAME}-{intl.formatMessage({ id: "MENU_DEALS" })}
        </title>
      </Helmet>
      {/* <AccountHeader /> */}
      <Switch>
        <Route path={`/content/deals/overview`}>
          <PageTitle breadcrumbs={dealsBreadCrumbs}>
            {intl.formatMessage({ id: "MENU_DEALS" })}
          </PageTitle>
          <DealsList pipelineId={id} />
        </Route>

        <Route path={`/content/deals/editDeal`}>
          <PageTitle
            breadcrumbs={[
              ...dealsBreadCrumbs,
              {
                title: "",
                path: "",
                isSeparator: true,
                isActive: false,
              },
              {
                title: intl.formatMessage({
                  id: "DEAL_EDIT_TITLE",
                }),
                path: "/content/deals/editDeal",
                isSeparator: false,
                isActive: true,
              },
            ]}
          >
            {intl.formatMessage({ id: "DEAL_EDIT_TITLE" })}
          </PageTitle>
          <EditDeal />
        </Route>

        <Redirect from="/content" exact={true} to={`/content/deals/overview`} />
        <Redirect to={`/content/deals/overview`} />
      </Switch>
    </div>
  );
};

export default DealsPage;
